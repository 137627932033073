$emphasis: #FF7838;

@import "include/adaptive";
@import "include/button";
@import "include/slick";
@import "include/slick-theme";

@font-face {
  font-family: Muller; /* Имя шрифта */
  src: url("/wp-content/themes/lab/css/fonts/muller/MullerBold.otf"); /* Путь к файлу со шрифтом */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Muller; /* Имя шрифта */
  src: url("/wp-content/themes/lab/css/fonts/muller/MullerRegular.otf"); /* Путь к файлу со шрифтом */
  font-weight: normal;
}

body {
  //background: blue;
  font-family: Ubuntu;
  padding: 0;
  margin: 0;
  color: #343434;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #e1e1e1;
}

::-webkit-scrollbar-thumb {
  background: $emphasis;
}

.section-title {
  font-family: Muller;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 0.5px;
  @include tablet() {
    text-align: center;
  }
  @include mobile() {
    font-size: 26px;
    line-height: 34px;
  }
  span {
    color: $emphasis;
  }
}

.boxed {
  padding-left: 5rem;
  padding-right: 5rem;
  height: 100vh;
  @include desktop() {
    height: auto;
    padding: 100px 5rem;
  }
  @include laptop() {
    padding: 60px 2rem;
  }
  @include tablet() {
    padding: 80px 1rem;
  }
  @include mobile() {
    padding: 40px 10px;
  }
}

a {
  transition-duration: 0.3s;
  color: #343434;
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

section {
  position: relative;
  height: 100vh;
  @include desktop() {
    height: auto;
    //padding: 100px 0;
  }
  //padding: 180px 0;
  //outline: 1px solid gray;

  &.light-section {

  }
  &.dark-section {
    color: white;
    .section-side-title {
      color: white;
    }
    .section-title {
      color: white;
    }
  }
  .section-side-title {
    position: absolute;
    font-size: 14px;
    top: 50%;
    left: 0;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    transform: rotate(-90deg) translateY(-50%);
    @include laptop() {
      display: none;
    }
  }
}
header {
  position: fixed;
  z-index: 2;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  padding: 20px 100px;
  justify-content: space-between;
  color: white;
  align-items: center;
  transition-duration: 0.2s;
  //background-color: rgba(0, 0, 0, 0.1);

  &.inner-page-header {
    background-color: #fff;
    color: #343434;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    .logo-white {
      display: none;
    }
    .logo-black {
      display: block;
    }
    a {
      color: #343434;
    }
    .menu-btn {
      .icon-bar {
        background-color: #333;
      }
    }
  }
  .mobile {
    display: none;
  }
  .logo-black {
    display: none;
  }
  @include mobile() {
    .web {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
  @include laptop() {
    padding: 20px 40px;
  }
  @include tablet() {
    padding: 10px 20px;
  }
  .logo {
    width: 150px;
    @include mobile() {
      width: 60px;
    }
    img {
      width: 100%;
      max-height: 60px;
    }
  }

}

header.scroll {
  color: #343434;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  .logo-white {
    display: none;
  }
  .logo-black {
    display: block;
  }
  a {
    color: #343434;
  }
  .menu-btn {
    .icon-bar {
      background-color: #333;
    }
  }
}

.menu-block {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35%;
  top: 0;
  bottom: 0;
  right: -35%;
  z-index: 100;
  background-color: #F9F9F9;
  padding: 80px;
  transition: all .6s ease;
  @include desktop() {
    padding: 60px;
  }
  @include laptop() {
    padding: 40px;
    width: 40%;
    right: -40%;
  }
  @include tablet() {
    width: 100%;
    right: -100%;
  }
  &.open {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);
    right: 0;
    .menu {
      opacity: 1;
      transform: translateY(0);
    }
    .soc-block {
      opacity: 1;
      transform: translateY(0);
    }
    .copy {
      opacity: 1;
      transform: translateY(0);
    }

  }
  ul.menu {
    transition: transform .5s .4s ease, opacity .5s .4s ease, color .3s ease;
    transform: translateY(3rem);
    opacity: 0;
    overflow-y: auto;
    max-height: 70%;
    list-style: none;
    font-family: Muller;
    text-transform: uppercase;
    font-size: 30px;
    .sub-menu {
      list-style: none;
      font-size: 20px;
      font-weight: 400;
      font-family: Ubuntu;
      text-transform: none;
      display: none;
      padding-left: 30px;
      a {
        padding: 10px 0;
      }
    }
    a {
      font-weight: 400;
      padding: 3.5% 0;
      display: block;
      @include laptop() {
        padding: 2% 0;
      }
      &.active {
        font-weight: bold;
      }
    }
  }
  .menu-item.open {
    /*transition: transform .5s .6s ease,opacity .5s .6s ease;*/
    a {
      padding-bottom: 10px;
    }
  }
  .soc-block {
    transition: transform .5s .6s ease, opacity .5s .5s ease, color .3s ease;
    transform: translateY(3rem);
    opacity: 0;
    font-size: 22px;
    a {
      padding: 10px;
      &:first-child {
        padding-left: 0;
      }
    }
  }
  .copy {
    transition: transform .5s .8s ease, opacity .5s .6s ease, color .3s ease;
    transform: translateY(3rem);
    opacity: 0;
    font-size: 14px;
    font-weight: 300;
    font-family: Ubuntu;
  }

  .menu-close {
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 40px;
    padding: 10px;
    z-index: 1;
    img {
      width: 30px;
      pointer-events: none;
    }
  }

}

.phone-top {
  @include desktop() {
    display: none;
  }
  a {
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    &:hover {
      color: $emphasis;
    }
  }
}

.soc-top {
  @include tablet() {
    display: none;
  }
  a {
    margin-right: 30px;
    color: #fff;
    font-size: 20px;
    &:hover {
      color: $emphasis;
    }
  }
}

.menu-btn {
  text-align: center;
  cursor: pointer;
  padding: 10px;
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: #fff;
  }
  .icon-bar + .icon-bar {
    margin-top: 4px;
  }
}

section.first-block {
  //background-image: url('/wp-content/themes/lab/assets/img/first-bg.png');
  -webkit-background-size: cover;
  background-size: cover;
 /*display: flex;
  align-items: center;
  justify-content: center;*/
  height: 100vh;
  @include desktop() {
    padding: 60px 0;
    padding-top: 80px;
  }
  @include laptop() {
    padding: 40px 0;
    padding-top: 80px;
  }
  .boxed {
    @include desktop() {
      padding: 40px 4rem;
    }
    @include laptop() {
      padding: 10px;
    }
  }
  .container {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.ms-item {
  color: white;
  .ms-mini-desc {
    font-size: 16px;
    font-weight: 300;
    padding-bottom: 50px;
    @include desktop() {
      padding-bottom: 30px;
    }
  }
  .ms-title {
    text-transform: uppercase;
    font-family: Muller;
    font-size: 46px;
    line-height: 60px;
    font-weight: bold;
    max-width: 700px;
    padding-bottom: 50px;
    @include desktop() {
      font-size: 40px;
      line-height: 50px;
      padding-bottom: 40px;
    }
    @include laptop() {
      font-size: 36px;
      line-height: 48px;
    }
    @include tablet() {
      font-size: 28px;
      line-height: 34px;
    }
    span {
      color: $emphasis;
    }
  }
  .ms-btn {
    position: relative;
  }
  .ms-btn:after {
    position: absolute;
    content: '';
    height: 6px;
    width: 100px;
    background-color: $emphasis;
    border-radius: 50px;
    top: 100px;
    left: 0;
    pointer-events: none;
  }
}

.wwd {
  color: #343434;
  .my-vertical-align {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .container {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .section-title {
    padding-bottom: 50px;
    padding-top: 40px;
  }
  ul.list-dir {
    list-style: none;
    li {
      padding-bottom: 30px;
    }
    a {
      text-decoration: none;
      color: #7b7b7b;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
    a:hover {
      color: #333;
    }
  }
}

.wwd-slogan {
  text-align: left;
}

.wwd-btn {
  @include laptop() {
    margin-bottom: 40px;
  }
  &:hover {
    padding-left: 20px;
  }
}

.vert-cent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.img-block {
  position: relative;
  margin-left: 130px;
  margin-bottom: 130px;
  @include desktop() {
    margin-left: 60px;
    margin-bottom: 110px;
  }
  @include mobile() {
    margin-left: 40px;
    margin-bottom: 80px;
  }
  img {
    width: 100%;
    max-height: 360px;
    min-height: 360px;
    object-fit: cover;
  }
}

.img-block-dots {
  position: absolute;
  z-index: -1;
  left: -25%;
  bottom: -19%;
  width: 101%;
  top: 50%;
  background: url("/wp-content/themes/lab/assets/img/dots-dark2.png") 0 0 repeat;
  @include desktop() {
    left: -10%;
  }
  @include mobile() {
    left: -15%;
  }
}

.grid-type1 {
  display: flex;
  @include mobile() {
    flex-direction: column;
  }
}

.grid-item {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #343434;
  box-sizing: border-box;
  width: 33.33333%;
  @include mobile() {
    width: 100%;
    border: none;
    min-height: 100px;
  }
}

.grid-item:nth-child(1),
.grid-item:nth-child(3) {
  border-left: none;
  border-right: none;
}


.footer-email{
  margin-left: -15px;
  font-size: 14px;
  @include laptop(){
    margin-left: 0;
    font-size: 14px;
  }
}

.grid-type1 {
  .title {
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    padding-top: 10px;
    letter-spacing: 0.5px;
  }
  .number {
    font-size: 36px;
    font-weight: bold;
    color: $emphasis;
  }
}

.grid-type2 {
  display: flex;
  @include mobile() {
    flex-direction: column;
  }
  .grid-item:nth-child(1) {
    padding-left: 0;
  }
  .grid-item:nth-child(3){
    padding-right: 0;
  }
  p {
    margin: 0;
  }
  .grid-item {
    align-items: flex-start;
    flex-direction: row;
    @include mobile() {
      border-bottom: 1px solid #343434;
    }
    .row {
      @include mobile() {
        width: 100%;
      }
    }
  }
  .grid-text {

    font-size: 14px;
    font-family: Ubuntu;
    p {
      padding-bottom: 10px;
      font-weight: 600;
    }
    a {
      color: #777;
      padding-bottom: 30px;
      display: block;
      &:last-child {
        @include mobile() {
          padding-bottom: 0;
        }
      }

      &:hover {
        color: #343434;
      }
    }
    span {
      color: #777;
      line-height: 20px;
    }
  }
}

.portfolio {
  background-color: black;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  .section-title {
    padding-bottom: 100px;
    position: relative;
    z-index: 1;
    @include mobile() {
      padding-bottom: 30px;
    }
  }
}

.portfolio-grid {
  .item {
    padding-right: 30px;
    padding-bottom: 30px;
  }
  .title {
    min-height: 200px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    transition-duration: 0.2s;
    cursor: pointer;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    @include tablet() {
      background-color: rgba(0, 0, 0, 0.6);
    }
    &:hover {
      border-color: $emphasis;
      background-color: $emphasis;
    }
  }
}

.img-bg-portfolio {
  z-index: 0;
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  opacity: 0;
  will-change: opacity, transform;
  transition: opacity .5s .2s ease, transform 5s .2s ease;
  transform: scale(1);
  &.active {
    opacity: 1;
    transform: scale(1.05);
  }
}

@keyframes scale-img {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.btn-portfolio {
  color: white;
  text-align: right;
  padding-right: 50px;
  display: inline-block;
  float: right;
  &:hover {
    padding-right: 30px;
  }
}

.ww-offer {
  padding-left: 100px;
  padding-right: 100px;
  @include desktop() {
    padding-left: 60px;
    padding-right: 60px;
  }
  @include laptop() {
    padding-left: 10px;
    padding-right: 10px;
  }
  .boxed {
    background: url("/wp-content/themes/lab/assets/img/dots-dark2.png") 0 0 repeat;
  }
  .section-title {
    padding-bottom: 4rem;
  }
}

.wwo-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    padding-bottom: 4rem;
  }
  &:last-child {
    @include tablet() {
      padding-bottom: 0;
    }
  }

  @include desktop() {
    padding-bottom: 4rem;
  }
  .wwo-icon {
    img {
      height: 76px;
    }
    padding-bottom: 50px;
  }
  .wwo-title {
    width: 100%;
    text-transform: uppercase;
    font-size: 20px;
    color: #343434;
    font-family: Muller;
    font-weight: bold;
    padding-bottom: 30px;
  }
  .wwo-desc {
    font-size: 16px;
    font-style: italic;
    line-height: 26px;
  }
}


.steps {
  background-image: url('/wp-content/themes/lab/assets/img/step-bg-min.jpg');
  -webkit-background-size: cover;
  background-size: cover;
  background-position: top left;
  .section-title {
    padding-bottom: 4rem;
    @include tablet() {
      padding-bottom: 2rem;
    }
  }
}

.btn-steps {
  @include tablet() {
    margin: 0 auto;
  }
}

.steps-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .step-item {
    position: relative;
    min-height: 180px;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    padding-left: 40px;
    z-index: 1;
    width: 30%;
    //background-image: url('/wp-content/themes/lab/assets/img/icon/step.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    @include desktop() {
      width: 50%;
    }
    @include tablet() {
      width: 100%;
      //padding-left: 10px;
      padding-left: 0;
      background-image: none;
      margin-bottom: 100px;
    }
    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 100%;
      background-image: url('/wp-content/themes/lab/assets/img/icon/step.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left center;
      @include tablet() {
        transform: translateX(-50%) rotate(90deg);
        left: 50%;
        top: 40px;
        width: 50px;
        height: 400px;
      }
      @include mobile() {
        width: 20px;
        height: 320px;
        top: 60px;
      }
    }
    &:last-child {
      @include tablet() {
        &:after {
          display: none;
        }
        margin-bottom: 0;
        min-height: 80px;
      }
    }
    &:first-child {
      &:after {
        display: none;
        @include tablet() {
          display: block;
        }
      }
      padding-left: 0;
      @include tablet() {
        padding-left: auto;
      }
      .step-number {
        left: -20px;
        @include tablet() {
          left: 20px;
        }
      }
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      width: 35%;
      @include desktop() {
        width: 50%;
      }
      @include tablet() {
        width: 100%;
      }
    }
  }
  .step-number {
    position: absolute;
    font-size: 260px;
    color: #ececec;
    top: -60px;
    font-family: Ubuntu;
    font-weight: bold;
    z-index: 0;
    left: 20px;
  }
  .step-text {
    position: relative;
    z-index: 0;
    padding-left: 30px;
    padding-right: 20px;
    font-family: Ubuntu;
    font-size: 16px;
    @include mobile() {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.partners {
  background-size: cover;
  padding-top: 80px;
  min-height: 100vh;
  height: auto;
  @include laptop(){
    min-height: auto;
  }
}

.section-title.quote {
  padding-bottom: 180px;
  padding-left: 80px;

  @include desktop() {
    padding-bottom: 50px;
    margin-top: 50px;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
  }
  @include mobile() {
    padding-left: 40px;
    margin-top: 20px;
    line-height: 28px;
    font-size: 22px;
  }
}

.quote {
  position: relative;
  &:before {
    position: absolute;
    content: '';
    top: -45px;
    width: 64px;
    height: 50px;
    left: 10px;
    background-repeat: no-repeat;
    background-image: url("/wp-content/themes/lab/assets/img/icon/quote.svg");
    background-size: contain;
    @include mobile() {
      width: 30px;
      top: -25px;
      height: 30px;
    }
  }
}

.rev-item {
  outline: none;
}

.rev-text {
  font-size: 14px;
  font-style: italic;
  line-height: 16px;
  letter-spacing: 0.5px;
  padding-bottom: 50px;
}

.rev-author {
  font-size: 14px;
  font-weight: bold;
  color: #343434;
  padding-bottom: 15px;
}

.rev-desc {
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
}
.rev-soc{
  font-size: 18px;
  padding-left: 15px;
}
.partners-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  .section-title {
    padding-bottom: 100px;
    @include desktop() {
      padding-bottom: 30px;
      padding-top: 70px;
    }
  }
}

.partners-logo {
  @include desktop() {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .p-img {
    padding-bottom: 50px;
    cursor: pointer;
    @include desktop() {
      width: 45%;
      text-align: center;
    }
    @include mobile() {
      width: 90%;
      margin: 0 auto;
    }
  }
}

.contact {
  .section-side-title{
    left: 40px;
  }
  .section-title {
    padding-bottom: 100px;
    @include mobile() {
      padding-bottom: 80px;
    }
  }
  #map {
    padding-bottom: 50px;
  }
  .grid-item {
    padding: 30px;
    @include desktop() {
      padding: 20px;
    }
    @include laptop() {
      padding: 15px;
    }
    @include mobile() {
      padding-bottom: 40px;
    }
  }
  .grid-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    text-transform: uppercase;
    font-family: Muller;
    @include desktop() {
      font-size: 18px;
      padding-bottom: 20px;
    }

  }
}

footer {
  background-color: #232323;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  @include mobile() {
    padding: 15px;
    flex-direction: column;
    height: auto;
    text-align: center;
  }
  a {
    color: white;
    &:hover {
      color: $emphasis;
    }
  }
  p {
    margin: 0;
    padding-right: 100px;
    @include mobile() {
      padding-right: 0;
      padding-bottom: 20px;
    }
  }
  .up-btn {
    cursor: pointer;
    color: $emphasis;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    transition-duration: 0.2s;
    .up-block {
      width: 30px;
      height: 30px;
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      img {
        width: 10px;
      }
    }
    &:hover {
      color: #fff;
      .up-block {
        //  background-color: #b5b5b5;
      }
    }
  }
}

.mymodal-container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.71);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 101;
  pointer-events: none;
  transition-duration: 0.2s;
}

.mymodal-container.open {
  opacity: 1;
  pointer-events: all;
}

.mymodal-body {
  position: relative;
  max-width: 1070px;
  width: 90%;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 100px;
  @include desktop() {
    padding: 60px;
    top: 60px;
  }

  @include tablet() {
    padding: 30px;
    top: 20px;
    width: 98%;
  }
}

.mymodal-close {
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.6;
  transition-duration: 0.3s;
  @include laptop() {
    top: 5px;
    right: 5px;
  }
  &:hover {
    opacity: 1;
  }
  img {
    pointer-events: none;
    width: 40px;
    @include laptop() {
      width: 20px;
    }
  }
}

.mymodal-title {
  font-size: 30px;
  font-weight: bold;
  font-family: Muller;
  text-align: center;
  width: 100%;
  letter-spacing: 0.5px;
  color: $emphasis;
  text-transform: uppercase;
  padding-bottom: 50px;
  padding-bottom: 0;
  @include laptop() {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
  }
}

.mymodal-form {
  input {
    width: 100%;
    line-height: 50px;
    background-color: #474747;
    color: #fff;
    border: none;
    padding: 0 30px;
    font-family: Ubuntu;
    &[type="submit"]{
      max-width: 250px;
      background: #ff7838;
    }
    &[type="file"]{
      background: transparent;
      color: #333333;
      display: flex;
      padding-bottom: 20px;
      padding-left: 0;
      outline:none;
      &:active{
        outline: none;
      }
    }


    @include laptop() {
      margin-bottom: 20px;
      line-height: 40px;
      padding: 0 20px;
    }
  }
  ::-webkit-input-placeholder {
    color: #fff;
  }
  ::-moz-placeholder {
    color: #fff;
  }
  :-ms-input-placeholder {
    color: #fff;
  }
  ::placeholder {
    color: #fff;
  }
  textarea {
    width: 100%;
    margin-top: 30px;
    background-color: #474747;
    color: #fff;
    padding: 30px;
    font-family: Ubuntu;
    border: none;
    @include laptop() {
      margin-top: 0;
      height: 100px;
      padding: 20px;
      margin-bottom: 20px;
    }
  }
  .bottom-line {
    display: flex;
    align-items: center;
    padding-top: 30px;
    @include laptop() {
      flex-wrap: wrap;
      padding-top: 20px;
    }
    //justify-content: space-between;
  }
}

.contact-form {
  @extend .mymodal-form;
}
.contact-form-container{
  padding-top: 100px;
  padding-bottom: 100px;
  @include laptop(){
    padding-top: 40px;
  }
}

.btn-mymodal {
  width: 250px;
  @include laptop() {

  }
}

.protect-text {
  width: 70%;
  color: #7b7b7b;
  padding-left: 50px;
  @include laptop() {
    width: 100%;
    padding-left: 0;
    padding-top: 20px;
  }
}

.inner-page {
  padding-top: 100px;
  background-color: #F9F9F9;
  section {
    height: auto;
  }
  .section-title {
    line-height: 48px;
    @include laptop() {
      line-height: 34px;
    }
  }
  .top-line {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    padding-top: 30px;
    line-height: 26px;
    text-transform: uppercase;
    @include laptop() {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 30px;
    }
    &:after {
      position: absolute;
      content: '';
      width: 100px;
      border-radius: 50px;
      border: 2px solid $emphasis;
      left: 0;
      top: 0;
      @include laptop() {
        top: auto;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.inner-page-desc {
  font-size: 16px;
  font-family: Ubuntu;
  line-height: 24px;
  p {
    margin-bottom: 20px;
  }
  a {
    color: $emphasis;
  }
}

.main-content {
  padding: 100px 0;
}

.breadcrumbs {
  text-transform: uppercase;
  padding-top: 50px;
  padding-bottom: 130px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: Ubuntu;
  @include laptop() {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .separator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #343434;
    border-right: 2px solid #343434;
    transform: rotate(45deg);
  }
  a {
    color: #969696;
    &:hover {
      color: #343434;
    }
  }
  span {
    margin: 0 10px;
  }
}

.side-line {
  width: 100px;
  border: 3px solid $emphasis;
  border-radius: 50px;
  font-family: Muller;
  @include laptop() {
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.wwd-container {
  background: url('/wp-content/themes/lab/assets/img/dots-dark2.png') 0 0 repeat;
  padding-top: 100px;
  @include laptop() {
    padding-top: 40px;
  }
  .section-title {
    padding-bottom: 50px;
  }
}

.wwd-item {
  @include laptop() {
    padding-bottom: 40px;
  }
  .icon-item {
    @include laptop() {
      text-align: center;
    }
    img {
      height: 76px;
    }
  }
  .title {
    font-size: 20px;
    font-family: Muller;
    font-weight: bold;
    line-height: 26px;
    text-transform: uppercase;
    padding-top: 50px;
    padding-bottom: 30px;
    width: 130px;
    @include laptop() {
      margin: 0 auto;
      text-align: center;
    }
  }
  .desc {
    width: 200px;
    font-size: 16px;
    font-style: italic;
    line-height: 26px;
    color: #343434;
    @include laptop() {
      width: auto;
      margin: 0 auto;
      text-align: center;
    }
  }
}

.inner-wwd {
  padding-bottom: 80px;
  @include laptop() {
    padding-bottom: 40px;
  }
}

.inner-partners-logo {
  display: flex;
  flex-wrap: wrap;
  a {
    width: 33%;
    padding-bottom: 50px;
    @include laptop() {
      width: 50%;
    }
    @include mobile() {
      width: 90%;
      margin: 0 auto;
      text-align: center;
    }
    img {
      width: 200px;
      @include laptop() {
        width: 90%;
      }
      @include mobile() {
        max-width: 200px;
      }
    }
  }
}

.inner-our-team {
  display: flex;
  flex-wrap: wrap;

}

.our-team {
  padding: 100px 0;
  @include laptop() {
    padding: 60px 0;
  }
}

.vacancy {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #343434;
  font-weight: 500;
  text-transform: uppercase;
  padding: 30px;
  box-sizing: border-box;
  margin-bottom: 20px;
  height: 100%;
  width: 100%;
  font-size: 20px;
  cursor:pointer;
  transition-duration: 0.2s;
  &:hover{
    color: #fff;
    background-color: #333;
  }
  @include laptop() {
    max-width: 250px;
    margin: 0 auto;
  }
  @include mobile() {
    height: 250px;
  }
}

div.team-item {
  width: 33%;
  padding: 20px;
  position: relative;
  transition-duration: 0.2s;
  img {
    width: 100%;
    @include laptop() {
      max-width: 250px;
      margin: 0 auto;

    }
  }
  @include laptop() {
    width: 50%;
    text-align: center;
  }
  @include mobile() {
    width: 100%;
  }
  .team-info{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition-duration: 0.2s;
    padding: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: #fff;
    flex-direction: column;
    font-family: Muller;
  }

  .team-name{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .team-position{
    font-size: 14px;
    text-transform: uppercase;
  }
  img{
    transition-duration: 0.2s;
  }
  &:hover{
    img{
      filter: brightness(40%);
    }
    .team-info{
      opacity: 1;
    }
  }
}


.about-container {
  padding-bottom: 20px;
}

.main-image {
  padding: 100px 60px;
  @include laptop() {
    padding: 40px 20px;
    padding-bottom: 30px;
  }
  img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }
}

.portfolio-section {
  margin: 0 60px;
  border-bottom: 1px solid rgba(52, 52, 52, 0.5);
  padding-bottom: 50px;
  @include laptop() {
    margin: 0 30px;
  }
}

.portfolio-slider {
  /* display: flex;
   overflow-x: scroll;*/

}

.portfolio-items-col {

  .portfolio-item {
    padding-bottom: 15px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 15px;
    width: 100%;
    height: 300px;
    display: block;
    @include tablet() {
      height: 200px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @include laptop() {
      height: 200px;
    }
    //outline:1px solid green;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }

  }
}

.slick-prev {
  @include laptop() {
    left: -5px;
  }
}

.slick-next {
  @include laptop() {
    right: -15px;
  }
}

.map {
  padding: 60px;
  @include laptop(){
    padding: 20px;
  }
}

.contact-page {
  .mini-title {
    font-size: 30px;
    font-family: Muller;
    font-weight: bold;
    padding-bottom: 30px;
  }
  .soc {
    display: flex;
    a{
      color: #343434;
      opacity: 0.65;
      font-size: 22px;
      padding-right: 30px;
    }

    a:hover{
      opacity: 1;
    }
  }
}
.soc-contact-container{
  padding-top: 100px;
  @include laptop(){
    padding-top: 40px;
  }
}
.block-mini-title{
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
  display: block;
  font-family: Muller;
}

.phone-block{
  padding-bottom: 50px;
  .block-mini-title{
    padding-bottom: 30px;
  }
  ul{
    list-style:none;
  }
  a{
    opacity: 0.65;
    font-size: 20px;
    padding-bottom: 10px;
    display: block;
  }
  a:hover{
    opacity: 1;
  }
}
.schedule{
  ul{
    list-style: none;
    color:#7a7a7a;
    font-size: 20px;
    padding-bottom: 30px;
  }
}

.contact-mini-block {
  ul {
    list-style: none;
    li {
      padding-bottom: 20px;
      span {
        font-family: Muller;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}

.contact-title {
  font-size: 30px;
  font-family: Muller;
  text-transform: uppercase;
  padding-bottom: 30px;
  span {
    font-weight: 800;
  }
}

.article-img{

  img{
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}
.article-item{
  position: relative;
  padding-bottom: 100%;
  height: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid transparent;
  transition-duration: 0.4s;

  .article-container{
    color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    padding: 30px;
    background-image: linear-gradient(transparent 60%, rgba(0, 0, 0, 0.75));
    display: flex;
    flex-direction: column;
    transition-duration: 0.2s;
  }

  .article-date{
    color: #949494;
    font-weight: 300;
    padding-bottom: 0;
  }

  .article-title{
    font-size: 20px;
    line-height: 26px;
    font-family: Muller;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 80%;
    transition-duration: 0.2s;
  }
  .article-desc{
    color:#949494;
    font-style: italic;
    max-height: 90px;
    overflow: hidden;
  }
  .article-btn{
    color: $emphasis;
    margin-top: auto;
    &:after{
      background-image: url('/wp-content/themes/lab/assets/img/right-arrow-white.svg');
    }
  }

}
.article-grid .row>div{
   padding-bottom: 30px;
 }

.hide-line{
  opacity: 0;
  height: 0;
  transition-duration: 0.2s;
}
.inner-page-title{
  .section-title{
    padding-bottom: 100px;
  }
}
.article-item:hover{
  border-color: $emphasis;
  .article-container{
    background-color: #343434;
  }

  .hide-line{
    opacity: 1;
    height: auto;
  }
  .article-date{
    padding-bottom: 50px;
  }
  .article-title{
    padding-top: 0;
    padding-bottom: 30px;
  }
  .article-desc{
    padding-bottom: 20px;
  }
}
.article-single{
  .section-title{
    padding-bottom: 40px;
  }
  .block-mini-title{
    text-transform: uppercase;
  }
}
.article-content{
  padding-right: 70px;
  p:first-child{
    margin-top: 0;
  }
  img{
    width: 100%;
    padding: 50px 0;
  }
}
.article-mini-desc{
  font-style: italic;
  padding-bottom: 50px;
}
.article-date{
  padding-bottom: 100px;
  font-size: 16px;
  font-weight: 300;
}

.sidebar{

}
.publication{

}
.pub-img{
  img{
    width: 70px;
    height: 70px;
    object-fit: cover;
  }
}
.pub-item{
  display: flex;
  padding-bottom: 20px;
}
.pub-text-block{
  padding-left: 20px;
}
.pub-title{
  font-size: 14px;
  line-height: 16px;
  &:hover{
    color:$emphasis;
  }
}
.pub-date{
  font-size: 12px;
  color:#777777;
  font-weight: 300;
  padding-top: 10px;
}
.projects-page{
  position: relative;
  margin: 0 76px;
  >.container{
    @include desktop(){
      display: flex;
      flex-wrap: wrap;
    }
  }
  @include laptop(){
    margin: 0;
  }
  &.main-content{
    padding-top: 50px;
  }
}
.pr-text{
  .section-title{
    @include  desktop(){
      display: none;
    }
  }
  @include desktop(){
    order: 2;
  }
}
.project-image{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  @include desktop(){
    position: static;
    transform:none;
    width: 100%;
    order: 1;
    margin-bottom: 40px;
  }
  img{
    width: 100%;
    max-height: 450px;
    object-fit: contain;
    object-position: right;
    @include desktop(){
      max-height: 400px;
      object-fit: cover;
    }
  }
  .section-title{
    display: none;
    padding-bottom: 40px;
    @include  desktop(){
      display: block;
    }
  }
}

.error-alert,
.good-alert{
  padding: 15px 0;
  color: red;
  display: block;
}
.good-alert{
  color: green;
}
@media screen and (max-height: 800px) {
  section{
    height: auto;
    .boxed{
      height: auto;
      padding-bottom: 40px;
      padding-top: 40px;
    }
  }
  .first-block{
    padding-top: 50px;
  }
  .mymodal-body{
    top: 10px;
    padding: 40px;
  }
  .menu-block{
    padding: 20px 80px;
  }
  .menu-block .menu-close{
    right: 10px;
  }
}
.screen-reader-response{
  padding: 20px 0;
  ul{
    display: none;
  }
}
.wpcf7-not-valid-tip{
  display: block;
  padding-top: 10px;
  font-size: 14px;
  color: red;
}